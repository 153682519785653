import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import BottomNavigation from './BottomNavigation';
import { supabase } from '../backend/supabaseClient';

function ConversationDetail() {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [conversation, setConversation] = useState(location.state?.conversation || null);
  const [loading, setLoading] = useState(!location.state?.conversation);
  const [error, setError] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);

  useEffect(() => {
    if (!conversation && id) {
      fetchConversationDetails();
    }
  }, [id, conversation]);

  const fetchConversationDetails = async () => {
    try {
      const response = await fetch(`https://api.retellai.com/v2/get-call/${id}`, {
        method: 'GET',
        headers: {
          'Authorization': 'Bearer 4bd6f633-e687-4ea7-acaa-05d5942d06ed',
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch conversation details');
      }

      const data = await response.json();
      setConversation(data);
      setLoading(false);
    } catch (err) {
      console.error('Error fetching conversation details:', err);
      setError(err.message || 'Failed to load conversation details');
      setLoading(false);
    }
  };

  const formatDate = (timestamp) => {
    return new Date(timestamp).toLocaleString();
  };

  const formatDuration = (start, end) => {
    const duration = (new Date(end) - new Date(start)) / 1000; // Convert to seconds
    const minutes = Math.floor(duration / 60);
    const seconds = Math.floor(duration % 60);
    return `${minutes}m ${seconds}s`;
  };

  if (loading) return <div className="text-center py-4 text-lg">Loading conversation details...</div>;
  if (error) return <div className="text-center text-red-500 py-4 text-lg">{error}</div>;
  if (!conversation) return <div className="text-center py-4 text-lg">Conversation not found.</div>;

  return (
    <div className="min-h-screen flex flex-col bg-white">
      <div className="flex-grow pb-20">
        <div className="flex items-center bg-white p-4 pb-2 justify-between">
          <button onClick={() => navigate(-1)} className="text-[#111418] flex size-12 shrink-0 items-center">
            <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" fill="currentColor" viewBox="0 0 256 256">
              <path d="M224,128a8,8,0,0,1-8,8H59.31l58.35,58.34a8,8,0,0,1-11.32,11.32l-72-72a8,8,0,0,1,0-11.32l72-72a8,8,0,0,1,11.32,11.32L59.31,120H216A8,8,0,0,1,224,128Z"></path>
            </svg>
          </button>
          <h1 className="text-[#111418]  text-xl font-bold leading-tight tracking-tight">
            Conversation Details
          </h1>
          <div className="size-12"></div> {/* Placeholder for alignment */}
        </div>

        <div className="mx-4 my-4 rounded-xl overflow-hidden bg-white shadow-md">
          <div className="p-6">
          <p className="text-[#111418] text-2xl font-bold mb-2">Summary</p>
          <p className="text-[#637588] text-lg mb-4">{conversation.call_analysis?.call_summary || 'No summary available'}</p>
          {conversation.recording_url && (
              <>
                <p className="text-[#111418] text-2xl font-bold  mb-4">Recording</p>
                <div className="bg-gray-100 rounded-lg p-6 shadow-md mb-6">
                  <div className="flex items-center justify-between">
                    <button 
                      className="bg-[#111418] hover:bg-[#2C3E50] text-white font-bold p-3 rounded-full flex items-center justify-center transition duration-300 ease-in-out"
                      onClick={() => {
                        const audio = document.getElementById('audioPlayer');
                        if (audio.paused) {
                          audio.play();
                          setIsPlaying(true);
                        } else {
                          audio.pause();
                          setIsPlaying(false);
                        }
                      }}
                    >
                      {isPlaying ? (
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" viewBox="0 0 20 20" fill="currentColor">
                          <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM7 8a1 1 0 012 0v4a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v4a1 1 0 102 0V8a1 1 0 00-1-1z" clipRule="evenodd" />
                        </svg>
                      ) : (
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" viewBox="0 0 20 20" fill="currentColor">
                          <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z" clipRule="evenodd" />
                        </svg>
                      )}
                    </button>
                    <div className="flex-grow mx-4">
                      <div className="bg-gray-300 h-2 rounded-full">
                        <div className="bg-blue-500 h-2 rounded-full" style={{width: '0%'}} id="progressBar"></div>
                      </div>
                    </div>
                    <span className="text-gray-600" id="currentTime">0:00</span>
                  </div>
                  <audio 
                    id="audioPlayer" 
                    src={conversation.recording_url} 
                    className="hidden"
                    onTimeUpdate={(e) => {
                      const audio = e.target;
                      const progressBar = document.getElementById('progressBar');
                      const currentTime = document.getElementById('currentTime');
                      const progress = (audio.currentTime / audio.duration) * 100;
                      progressBar.style.width = `${progress}%`;
                      currentTime.textContent = `${Math.floor(audio.currentTime / 60)}:${String(Math.floor(audio.currentTime % 60)).padStart(2, '0')}`;
                    }}
                    onPlay={() => setIsPlaying(true)}
                    onPause={() => setIsPlaying(false)}
                  />
                </div>
              </>
            )}
            

           

            <p className="text-[#111418] text-2xl font-bold mb-2">Transcript</p>
            <div className="bg-gray-100 p-4 rounded-lg max-h-96 overflow-y-auto mb-6">
              {conversation.transcript ? (
                <p className="text-[#637588] text-lg whitespace-pre-wrap">
                  {conversation.transcript.split('\n').map((line, index) => (
                    <span key={index}>
                      {line.startsWith('Agent:') ? (
                        <><span className="text-red-500">Agent</span>{line.slice(5)}<br /></>
                      ) : line.startsWith('User:') ? (
                        <><span className="text-blue-500">User</span>{line.slice(4)}<br /></>
                      ) : (
                        <>{line}<br /></>
                      )}
                    </span>
                  ))}
                </p>
              ) : (
                <p className="text-gray-500">No transcript available.</p>
              )}
            </div>
            <p className="text-[#111418] text-2xl font-bold mb-2">Call Information</p>
            <p className="text-[#637588] text-lg mb-2">Date: {formatDate(conversation.start_timestamp)}</p>
            <p className="text-[#637588] text-lg mb-2">Duration: {formatDuration(conversation.start_timestamp, conversation.end_timestamp)}</p>
            <p className="text-[#637588] text-lg mb-2">Direction: {conversation.direction}</p>
            <p className="text-[#637588] text-lg mb-4">Sentiment: {conversation.call_analysis?.user_sentiment || 'N/A'}</p>

            
          </div>
        </div>
      </div>
      <BottomNavigation activeTab="conversations" />
    </div>
  );
}

export default ConversationDetail;