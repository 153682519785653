import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation, Navigate } from 'react-router-dom';
import Home from './components/Home';
import Insights from './components/Insights';
import Conversations from './components/Conversations';
import ConversationDetail from './components/ConversationDetail'; // Add this import
import Settings from './components/Settings';
import Login from './components/Login';
import SignUp from './components/SignUp';
import AgentSettings from './components/AgentSettings'; // Add this import
import { supabase } from './backend/supabaseClient';
import { handleAuthStateChange } from './backend/auth';
import { QueryClient, QueryClientProvider } from 'react-query';

const queryClient = new QueryClient();

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkSession = async () => {
      const { data: { session } } = await supabase.auth.getSession();
      setUser(session?.user || null);
      setLoading(false);
    };

    checkSession();

    const { data: authListener } = supabase.auth.onAuthStateChange((event, session) => {
      console.log('Auth state changed:', event, session);
      handleAuthStateChange(event, session);
      setUser(session?.user || null);
    });

    return () => {
      if (authListener && typeof authListener.unsubscribe === 'function') {
        authListener.unsubscribe();
      }
    };
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <ScrollToTop />
        <div className="App min-h-screen flex flex-col">
          <div className="flex-grow">
            <Routes>
              <Route path="/" element={user ? <Home /> : <Navigate to="/login" />} />
              <Route path="/insights" element={user ? <Insights /> : <Navigate to="/login" />} />
              <Route path="/conversations" element={user ? <Conversations /> : <Navigate to="/login" />} />
              <Route path="/settings" element={user ? <Settings /> : <Navigate to="/login" />} />
              <Route path="/login" element={<Login />} />
              <Route path="/signup" element={<SignUp />} />
              <Route path="/conversation/:id" element={user ? <ConversationDetail /> : <Navigate to="/login" />} />
              <Route path="/agent-settings" element={user ? <AgentSettings /> : <Navigate to="/login" />} /> {/* Add this line */}
            </Routes>
          </div>
        </div>
      </Router>
    </QueryClientProvider>
  );
}

export default App;