import React from 'react';
import BottomNavigation from './BottomNavigation';
import { Link } from 'react-router-dom';

function Insights() {
  return (
    <div className="relative flex size-full min-h-screen flex-col bg-white justify-between group/design-root overflow-x-hidden" style={{ fontFamily: 'Inter, "Noto Sans", sans-serif' }}>
      <div>
        <div className="flex items-center bg-white p-4 pb-2 justify-between">
          <Link to="/" className="text-[#111418] flex size-12 shrink-0 items-center">
            <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" fill="currentColor" viewBox="0 0 256 256">
              <path d="M224,128a8,8,0,0,1-8,8H59.31l58.35,58.34a8,8,0,0,1-11.32,11.32l-72-72a8,8,0,0,1,0-11.32l72-72a8,8,0,0,1,11.32,11.32L59.31,120H216A8,8,0,0,1,224,128Z"></path>
            </svg>
          </Link>
          <h2 className="text-[#111418] text-xl font-bold leading-tight tracking-[-0.015em] flex-1 text-center pr-12">Insights</h2>
        </div>
        <div className="flex flex-wrap gap-4 p-4">
          <div className="flex min-w-[158px] flex-1 flex-col gap-2 rounded-xl p-6 bg-[#f0f2f4]">
            <p className="text-[#111418] text-base font-medium leading-normal">Happiness Score</p>
            <p className="text-[#111418] tracking-light text-2xl font-bold leading-tight">88/100</p>
          </div>
          <div className="flex min-w-[158px] flex-1 flex-col gap-2 rounded-xl p-6 bg-[#f0f2f4]">
            <p className="text-[#111418] text-base font-medium leading-normal">Last 7 days</p>
            <p className="text-[#111418] tracking-light text-2xl font-bold leading-tight">Average: 85</p>
          </div>
          <div className="flex min-w-[158px] flex-1 flex-col gap-2 rounded-xl p-6 bg-[#f0f2f4]">
            <p className="text-[#111418] text-base font-medium leading-normal">Emotion Breakdown</p>
            <p className="text-[#111418] tracking-light text-2xl font-bold leading-tight">Happy: 60%</p>
          </div>
          <div className="flex min-w-[158px] flex-1 flex-col gap-2 rounded-xl p-6 bg-[#f0f2f4]">
            <p className="text-[#111418] text-base font-medium leading-normal">Well-Being Tips</p>
            <p className="text-[#111418] tracking-light text-2xl font-bold leading-tight">Stay active to boost mood</p>
          </div>
        </div>
        {/* Daily Mood Trends */}
        <div className="flex flex-wrap gap-4 px-4 py-6">
          <div className="flex min-w-72 flex-1 flex-col gap-2 rounded-xl border border-[#dce0e5] p-6">
            <p className="text-[#111418] text-base font-medium leading-normal">Daily Mood Trends</p>
            <p className="text-[#637588] text-base font-normal leading-normal">Last 30 Days</p>
            <div className="flex min-h-[180px] flex-1 flex-col gap-8 py-4">
              <svg width="100%" height="148" viewBox="-3 0 478 150" fill="none" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none">
                <path d="M0 109C18.1538 109 18.1538 21 36.3077 21C54.4615 21 54.4615 41 72.6154 41C90.7692 41 90.7692 93 108.923 93C127.077 93 127.077 33 145.231 33C163.385 33 163.385 101 181.538 101C199.692 101 199.692 61 217.846 61C236 61 236 45 254.154 45C272.308 45 272.308 121 290.462 121C308.615 121 308.615 149 326.769 149C344.923 149 344.923 1 363.077 1C381.231 1 381.231 81 399.385 81C417.538 81 417.538 129 435.692 129C453.846 129 453.846 25 472 25V149H326.769H0V109Z" fill="url(#paint0_linear_1131_5935)"></path>
                <path d="M0 109C18.1538 109 18.1538 21 36.3077 21C54.4615 21 54.4615 41 72.6154 41C90.7692 41 90.7692 93 108.923 93C127.077 93 127.077 33 145.231 33C163.385 33 163.385 101 181.538 101C199.692 101 199.692 61 217.846 61C236 61 236 45 254.154 45C272.308 45 272.308 121 290.462 121C308.615 121 308.615 149 326.769 149C344.923 149 344.923 1 363.077 1C381.231 1 381.231 81 399.385 81C417.538 81 417.538 129 435.692 129C453.846 129 453.846 25 472 25" stroke="#637588" strokeWidth="3" strokeLinecap="round"></path>
                <defs>
                  <linearGradient id="paint0_linear_1131_5935" x1="236" y1="1" x2="236" y2="149" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#f0f2f4"></stop>
                    <stop offset="1" stopColor="#f0f2f4" stopOpacity="0"></stop>
                  </linearGradient>
                </defs>
              </svg>
              <div className="flex justify-around">
                <p className="text-[#637588] text-[13px] font-bold leading-normal tracking-[0.015em]">1D</p>
                <p className="text-[#637588] text-[13px] font-bold leading-normal tracking-[0.015em]">1W</p>
                <p className="text-[#637588] text-[13px] font-bold leading-normal tracking-[0.015em]">1M</p>
              </div>
            </div>
          </div>
        </div>
        {/* Weekly Mood Trends */}
        <div className="flex flex-wrap gap-4 px-4 py-6">
          <div className="flex min-w-72 flex-1 flex-col gap-2 rounded-xl border border-[#dce0e5] p-6">
            <p className="text-[#111418] text-base font-medium leading-normal">Weekly Mood Trends</p>
            <p className="text-[#637588] text-base font-normal leading-normal">Last 6 months</p>
            <div className="grid min-h-[180px] grid-flow-col gap-6 grid-rows-[1fr_auto] items-end justify-items-center px-3">
              {[60, 40, 10, 20, 60, 90].map((height, index) => (
                <React.Fragment key={index}>
                  <div className="border-[#637588] bg-[#f0f2f4] border-t-2 w-full" style={{ height: `${height}%` }}></div>
                  <p className="text-[#637588] text-[13px] font-bold leading-normal tracking-[0.015em]">{index + 1}</p>
                </React.Fragment>
              ))}
            </div>
          </div>
        </div>
        {/* Emotion Breakdown */}
        <div className="flex flex-wrap gap-4 px-4 py-6">
          <div className="flex min-w-72 flex-1 flex-col gap-2 rounded-xl border border-[#dce0e5] p-6">
            <p className="text-[#111418] text-base font-medium leading-normal">Emotion Breakdown</p>
            <p className="text-[#111418] tracking-light text-[32px] font-bold leading-tight truncate">60%, 20%, 10%, 10%</p>
            <div className="grid min-h-[180px] gap-x-4 gap-y-6 grid-cols-[auto_1fr] items-center py-3">
              {[
                { label: 'Happy', width: '60%' },
                { label: 'Sad', width: '20%' },
                { label: 'Angry', width: '10%' },
                { label: 'Calm', width: '10%' },
              ].map(({ label, width }, index) => (
                <React.Fragment key={index}>
                  <p className="text-[#637588] text-[13px] font-bold leading-normal tracking-[0.015em]">{label}</p>
                  <div className="h-full flex-1">
                    <div className="border-[#637588] bg-[#f0f2f4] border-r-2 h-full" style={{ width }}></div>
                  </div>
                </React.Fragment>
              ))}
            </div>
          </div>
        </div>
        {/* Well-Being Tips */}
        <h2 className="text-[#111418] text-[22px] font-bold leading-tight tracking-[-0.015em] px-4 pb-3 pt-5">Well-Being Tips</h2>
        {['Stay active to boost mood', 'Get enough sleep', 'Eat healthy', 'Connect with others'].map((tip, index) => (
          <div key={index} className="flex items-center gap-4 bg-white px-4 min-h-[72px] py-2">
            <div className="flex flex-col justify-center">
              <p className="text-[#111418] text-base font-medium leading-normal line-clamp-1">{tip}</p>
              <p className="text-[#637588] text-sm font-normal leading-normal line-clamp-2">Based on your mood patterns</p>
            </div>
          </div>
        ))}
        {/* Historical Data */}
        <h2 className="text-[#111418] text-[22px] font-bold leading-tight tracking-[-0.015em] px-4 pb-3 pt-5">Historical Data</h2>
        <div className="flex flex-wrap gap-4 px-4 py-6">
          <div className="flex min-w-72 flex-1 flex-col gap-2 rounded-xl border border-[#dce0e5] p-6">
            <p className="text-[#111418] text-base font-medium leading-normal">Mood Trends</p>
            <p className="text-[#637588] text-base font-normal leading-normal">Last 30 Days</p>
            <div className="flex min-h-[180px] flex-1 flex-col gap-8 py-4">
              <svg width="100%" height="148" viewBox="-3 0 478 150" fill="none" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none">
                <path d="M0 109C18.1538 109 18.1538 21 36.3077 21C54.4615 21 54.4615 41 72.6154 41C90.7692 41 90.7692 93 108.923 93C127.077 93 127.077 33 145.231 33C163.385 33 163.385 101 181.538 101C199.692 101 199.692 61 217.846 61C236 61 236 45 254.154 45C272.308 45 272.308 121 290.462 121C308.615 121 308.615 149 326.769 149C344.923 149 344.923 1 363.077 1C381.231 1 381.231 81 399.385 81C417.538 81 417.538 129 435.692 129C453.846 129 453.846 25 472 25V149H326.769H0V109Z" fill="url(#paint0_linear_1131_5935)"></path>
                <path d="M0 109C18.1538 109 18.1538 21 36.3077 21C54.4615 21 54.4615 41 72.6154 41C90.7692 41 90.7692 93 108.923 93C127.077 93 127.077 33 145.231 33C163.385 33 163.385 101 181.538 101C199.692 101 199.692 61 217.846 61C236 61 236 45 254.154 45C272.308 45 272.308 121 290.462 121C308.615 121 308.615 149 326.769 149C344.923 149 344.923 1 363.077 1C381.231 1 381.231 81 399.385 81C417.538 81 417.538 129 435.692 129C453.846 129 453.846 25 472 25" stroke="#637588" strokeWidth="3" strokeLinecap="round"></path>
                <defs>
                  <linearGradient id="paint0_linear_1131_5935" x1="236" y1="1" x2="236" y2="149" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#f0f2f4"></stop>
                    <stop offset="1" stopColor="#f0f2f4" stopOpacity="0"></stop>
                  </linearGradient>
                </defs>
              </svg>
              <div className="flex justify-around">
                <p className="text-[#637588] text-[13px] font-bold leading-normal tracking-[0.015em]">1D</p>
                <p className="text-[#637588] text-[13px] font-bold leading-normal tracking-[0.015em]">1W</p>
                <p className="text-[#637588] text-[13px] font-bold leading-normal tracking-[0.015em]">1M</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <BottomNavigation activeTab="insights" />
    </div>
  );
}

export default Insights;