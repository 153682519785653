import React, { useState, useCallback, useMemo, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useQuery, useQueryClient } from 'react-query';
import BottomNavigation from './BottomNavigation';
import { supabase } from '../backend/supabaseClient';
import PullToRefresh from 'react-pull-to-refresh';

const CONVERSATIONS_PER_PAGE = 50;
const INITIAL_DISPLAY_COUNT = 25;

function Conversations() {
  const [displayCount, setDisplayCount] = useState(INITIAL_DISPLAY_COUNT);
  const [playingAudio, setPlayingAudio] = useState(null);
  const [audioProgress, setAudioProgress] = useState({});
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const scrollRef = useRef(null);

  const fetchConversations = useCallback(async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('No user logged in');

      const { data: agentSettings, error: agentError } = await supabase
        .from('agent_settings')
        .select('agent_id')
        .eq('user_id', user.id)
        .single();

      if (agentError) throw agentError;

      // Fetch conversations from Retell API
      const response = await fetch('https://api.retellai.com/v2/list-calls', {
        method: 'POST',
        headers: {
          'Authorization': 'Bearer 4bd6f633-e687-4ea7-acaa-05d5942d06ed',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          filter_criteria: {
            agent_id: [agentSettings.agent_id]
          },
          limit: CONVERSATIONS_PER_PAGE // Using the updated constant
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to fetch conversations from Retell API');
      }

      const retellData = await response.json();

      // Here you would process the retellData and save it to Supabase if needed

      return retellData; // Or return processed data
    } catch (error) {
      console.error('Error fetching conversations:', error);
      throw error;
    }
  }, []);

  const { data: allConversations, isLoading, error, refetch } = useQuery(
    'conversations',
    fetchConversations,
    {
      refetchOnWindowFocus: false,
    }
  );

  const displayedConversations = useMemo(() => {
    return allConversations?.slice(0, displayCount) || [];
  }, [allConversations, displayCount]);

  const handleRefresh = async () => {
    setDisplayCount(INITIAL_DISPLAY_COUNT);
    await refetch();
    if (scrollRef.current) {
      scrollRef.current.scrollTop = 0;
    }
  };

  const loadMore = () => {
    setDisplayCount(prevCount => Math.min(prevCount + 25, CONVERSATIONS_PER_PAGE));
  };

  const handlePlayPause = useCallback((recordingUrl, callId) => {
    if (playingAudio && playingAudio.callId === callId) {
      playingAudio.audio.pause();
      setPlayingAudio(null);
    } else {
      if (playingAudio) {
        playingAudio.audio.pause();
      }
      const audio = new Audio(recordingUrl);
      audio.play();
      setPlayingAudio({ audio, callId });
      audio.addEventListener('ended', () => setPlayingAudio(null));
      audio.addEventListener('timeupdate', () => {
        setAudioProgress(prev => ({
          ...prev,
          [callId]: audio.currentTime / audio.duration
        }));
      });
    }
  }, [playingAudio]);

  const handleViewDetails = useCallback((conversation) => {
    navigate(`/conversation/${conversation.call_id}`, { state: { conversation } });
  }, [navigate]);

  const formatDate = useMemo(() => (timestamp) => {
    return new Date(timestamp).toLocaleString([], { weekday: 'short', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' });
  }, []);

  const formatDuration = useMemo(() => (start, end) => {
    const startDate = new Date(start);
    const endDate = new Date(end);
    const durationInSeconds = Math.floor((endDate - startDate) / 1000);
    const minutes = Math.floor(durationInSeconds / 60);
    const seconds = durationInSeconds % 60;
    return `${minutes}:${seconds.toString().padStart(2, '0')}`;
  }, []);

  const truncateText = useMemo(() => (text, maxLength) => {
    if (text.length <= maxLength) return text;
    return text.substr(0, maxLength) + '...';
  }, []);

  return (
    <div className="relative flex size-full min-h-screen flex-col bg-white group/design-root overflow-x-hidden" style={{ fontFamily: 'Inter, "Noto Sans", sans-serif' }}>
      <div className="sticky top-0 z-50 bg-white">
        <div className="flex items-center bg-white p-4 pb-2 justify-between">
          <Link to="/" className="text-[#111418] flex size-12 shrink-0 items-center">
            <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" fill="currentColor" viewBox="0 0 256 256">
              <path d="M224,128a8,8,0,0,1-8,8H59.31l58.35,58.34a8,8,0,0,1-11.32,11.32l-72-72a8,8,0,0,1,0-11.32l72-72a8,8,0,0,1,11.32,11.32L59.31,120H216A8,8,0,0,1,224,128Z"></path>
            </svg>
          </Link>
          <h1 className="text-[#111418] text-xl font-bold leading-tight tracking-tight">
            Conversations
          </h1>
          <div className="size-12"></div>
        </div>
      </div>

      <PullToRefresh onRefresh={handleRefresh}>
        <div className="flex-grow pb-24 overflow-y-auto" ref={scrollRef}>
          {isLoading && <p className="text-center py-4">Loading conversations...</p>}
          {error && <p className="text-center text-red-500 py-4">{error.message}</p>}

          {displayedConversations.length > 0 ? (
            <>
              {displayedConversations.map((conversation) => (
                <div key={conversation.call_id} className="p-4">
                  <div className="flex flex-col gap-4 rounded-xl bg-white shadow-md p-4">
                    <div className="flex justify-between items-start">
                      <div className="flex-1">
                        <p className="text-[#637588] text-sm mb-2">{formatDate(conversation.start_timestamp)}</p>
                        <p className="text-[#111418] text-base font-bold leading-tight mb-1">
                          {truncateText(conversation.call_analysis.call_summary, 50)}
                        </p>
                      </div>
                      <div className="relative w-14 h-14 flex items-center justify-center">
                        <button
                          className="absolute z-10 flex items-center justify-center rounded-full bg-gray-200 w-12 h-12"
                          onClick={() => handlePlayPause(conversation.recording_url, conversation.call_id)}
                        >
                          {playingAudio && playingAudio.callId === conversation.call_id ? (
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                              <rect x="6" y="4" width="4" height="16"></rect>
                              <rect x="14" y="4" width="4" height="16"></rect>
                            </svg>
                          ) : (
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                              <polygon points="5 3 19 12 5 21 5 3"></polygon>
                            </svg>
                          )}
                        </button>
                        <svg className="absolute w-14 h-14 -rotate-90">
                          <circle
                            cx="28"
                            cy="28"
                            r="26"
                            fill="none"
                            stroke="#e5e7eb"
                            strokeWidth="2"
                          />
                          {playingAudio && playingAudio.callId === conversation.call_id && (
                            <circle
                              cx="28"
                              cy="28"
                              r="26"
                              fill="none"
                              stroke="#1980e6"
                              strokeWidth="2"
                              strokeDasharray="163.36"
                              strokeDashoffset={163.36 - (163.36 * (audioProgress[conversation.call_id] || 0))}
                            />
                          )}
                        </svg>
                      </div>
                    </div>
                    <div
                      className="w-full bg-center bg-no-repeat aspect-video bg-cover rounded-xl"
                      style={{ backgroundImage: `url("https://cdn.usegalileo.ai/stability/5171a3e4-a1ca-46dc-8f64-44126fd6169b.png")` }}
                    ></div>
                    <div className="flex justify-between items-center">
                      <div>
                        <p className="text-[#111418] text-sm font-medium">
                          Duration: {formatDuration(conversation.start_timestamp, conversation.end_timestamp)}
                        </p>
                        <div className="flex items-center">
                          <p className="text-[#637588] text-xs mr-2">Sentiment: {conversation.call_analysis.user_sentiment}</p>
                          <div className={`w-3 h-3 rounded-full ${
                            conversation.call_analysis.user_sentiment === 'Positive' ? 'bg-green-500' :
                            conversation.call_analysis.user_sentiment === 'Negative' ? 'bg-red-500' :
                            'bg-yellow-500'
                          }`}></div>
                        </div>
                      </div>
                      <button
                        className="text-[#1980e6] text-sm font-medium"
                        onClick={() => handleViewDetails(conversation)}
                      >
                        View Details
                      </button>
                    </div>
                  </div>
                </div>
              ))}
              {displayCount < CONVERSATIONS_PER_PAGE && allConversations?.length > displayCount && (
                <div className="flex justify-center mt-4 mb-8">
                  <button 
                    onClick={loadMore}
                    className="bg-black hover:bg-gray-800 text-white font-bold py-2 px-4 rounded"
                  >
                    Load More
                  </button>
                </div>
              )}
            </>
          ) : (
            <p className="text-center py-4">No conversations found.</p>
          )}
        </div>
      </PullToRefresh>

      <div className="sticky bottom-0 z-50">
        <BottomNavigation activeTab="conversations" />
      </div>
    </div>
  );
}

export default React.memo(Conversations);