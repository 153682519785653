import { supabase } from './supabaseClient';

export const signUp = async (email, password) => {
  console.log('signUp function called with email:', email);
  try {
    const { data, error } = await supabase.auth.signUp({
      email,
      password,
    });

    if (error) {
      console.error('Supabase signUp error:', error);
      throw error;
    }

    if (data && data.user) {
      console.log('User signed up successfully:', data.user);
      await createUserProfile(data.user.id);
      return data.user;
    } else {
      console.error('Sign up successful, but no user data returned');
      throw new Error('Sign up successful, but no user data returned');
    }
  } catch (error) {
    console.error('Sign-up error:', error);
    throw error;
  }
};

export const signIn = async (email, password) => {
  try {
    const { data, error } = await supabase.auth.signInWithPassword({
      email,
      password,
    });

    if (error) throw error;

    return data.user;
  } catch (error) {
    console.error('Sign-in error:', error);
    throw error;
  }
};

export const signOut = async () => {
  const { error } = await supabase.auth.signOut();
  if (error) throw error;
};

export const getCurrentUser = async () => {
  const { data: { user } } = await supabase.auth.getUser();
  return user;
};

export const createUserProfile = async (userId) => {
  const { data, error } = await supabase
    .from('user_profiles')
    .insert([{ user_id: userId }]);

  if (error) {
    console.error('Error creating user profile:', error);
    throw error;
  }

  return data;
};

export const updateUserProfile = async (userId, updates) => {
  const { data, error } = await supabase
    .from('user_profiles')
    .update(updates)
    .eq('user_id', userId);

  if (error) throw error;

  return data;
};

export const getUserProfile = async (userId) => {
  const { data, error } = await supabase
    .from('user_profiles')
    .select('*')
    .eq('user_id', userId)
    .single();

  if (error) throw error;

  return data;
};

export const handleAuthStateChange = async (event, session) => {
  console.log('handleAuthStateChange called with event:', event);
  if (event === 'SIGNED_IN') {
    if (session && session.user) {
      const { user } = session;
      console.log('User signed in:', user);

      // Check if user profile exists
      const { data: existingProfile, error: profileError } = await supabase
        .from('user_profiles')
        .select()
        .eq('user_id', user.id)
        .single();

      if (profileError && profileError.code !== 'PGRST116') {
        console.error('Error checking user profile:', profileError);
      }

      if (!existingProfile) {
        console.log('Creating user profile for:', user.id);
        // Create user profile if it doesn't exist
        try {
          await createUserProfile(user.id);
        } catch (error) {
          console.error('Error creating user profile:', error);
        }
      } else {
        console.log('User profile already exists for:', user.id);
      }
    } else {
      console.error('Session or user is undefined in SIGNED_IN event');
    }
  } else if (event === 'SIGNED_OUT') {
    console.log('User signed out');
  }
};