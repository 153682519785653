import { useState, useEffect } from 'react';
import { supabase } from '../backend/supabaseClient';

const CACHE_KEY = 'agentSettings';
const CACHE_EXPIRATION = 24 * 60 * 60 * 1000; // 24 hours

export function useAgentSettings() {
  const [agentName, setAgentName] = useState('');
  const [language, setLanguage] = useState('');
  const [clientName, setClientName] = useState('');
  const [loading, setLoading] = useState(true);

  const fetchAndCacheSettings = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('No user logged in');

      const { data, error } = await supabase
        .from('agent_settings')
        .select('agent_name, language, client_name')
        .eq('user_id', user.id)
        .single();

      if (error) throw error;

      if (data) {
        setAgentName(data.agent_name || 'Kristin');
        setLanguage(data.language || 'English');
        setClientName(data.client_name || '');
        localStorage.setItem(CACHE_KEY, JSON.stringify({
          agentName: data.agent_name || 'Kristin',
          language: data.language || 'English',
          clientName: data.client_name || '',
          timestamp: Date.now()
        }));
      }
    } catch (error) {
      console.error('Error fetching agent settings:', error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const cachedData = JSON.parse(localStorage.getItem(CACHE_KEY));
    if (cachedData && Date.now() - cachedData.timestamp < CACHE_EXPIRATION) {
      setAgentName(cachedData.agentName);
      setLanguage(cachedData.language);
      setClientName(cachedData.clientName);
      setLoading(false);
    } else {
      fetchAndCacheSettings();
    }
  }, []);

  const updateSettings = async (newAgentName, newLanguage, newClientName) => {
    setAgentName(newAgentName);
    setLanguage(newLanguage);
    setClientName(newClientName);
    localStorage.setItem(CACHE_KEY, JSON.stringify({
      agentName: newAgentName,
      language: newLanguage,
      clientName: newClientName,
      timestamp: Date.now()
    }));
  };

  return { agentName, language, clientName, loading, updateSettings };
}