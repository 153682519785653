import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import BottomNavigation from './BottomNavigation';
import { supabase } from '../backend/supabaseClient';
import { useAgentSettings } from '../hooks/useAgentSettings';

const languageOptions = {
  'English (US)': 'en-US',
  'English (UK)': 'en-GB',
  'English (India)': 'en-IN',
  'Multilingual': 'multi',
  'Spanish (Spain)': 'es-ES',
  'Spanish (Latin America)': 'es-419',
  'French': 'fr-FR',
  'Mandarin': 'zh-CN',
  'German': 'de-DE',
  'Hindi': 'hi-IN',
  'Japanese': 'ja-JP',
  'Portuguese (Portugal)': 'pt-PT',
  'Portuguese (Brazil)': 'pt-BR',
  'Russian': 'ru-RU',
  'Italian': 'it-IT',
  'Korean': 'ko-KR',
  'Dutch': 'nl-NL',
  'Polish': 'pl-PL',
  'Turkish': 'tr-TR',
  'Vietnamese': 'vi-VN'
};

function AgentSettings() {
  const navigate = useNavigate();
  const { agentName: cachedAgentName, language: cachedLanguage, clientName: cachedClientName, updateSettings } = useAgentSettings();
  const [clientName, setClientName] = useState(cachedClientName);
  const [agentName, setAgentName] = useState(cachedAgentName);
  const [language, setLanguage] = useState(cachedLanguage);
  const [medicalInfo, setMedicalInfo] = useState('');
  const [hobbies, setHobbies] = useState('');
  const [context, setContext] = useState('');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');

  const agentOptions = ['Kristin', 'Kate', 'Sarah', 'Catherine', 'Emily'];

  useEffect(() => {
    fetchAgentSettings();
  }, []);

  const fetchAgentSettings = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('No user logged in');

      const { data, error } = await supabase
        .from('agent_settings')
        .select('*')
        .eq('user_id', user.id)
        .single();

      if (error) throw error;

      if (data) {
        setClientName(data.client_name || '');
        setAgentName(data.agent_name || cachedAgentName);
        setLanguage(data.language || cachedLanguage);
        setMedicalInfo(data.medical_info || '');
        setHobbies(data.hobbies || '');
        setContext(data.context || '');
      }
    } catch (error) {
      console.error('Error fetching agent settings:', error.message);
      setMessage('Failed to load settings. Please try again.');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setMessage('');

    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('No user logged in');

      const { data: existingSettings, error: fetchError } = await supabase
        .from('agent_settings')
        .select('*')
        .eq('user_id', user.id)
        .single();

      if (fetchError && fetchError.code !== 'PGRST116') {
        throw fetchError;
      }

      let result;
      if (existingSettings) {
        result = await supabase
          .from('agent_settings')
          .update({
            agent_id: 'agent_c6d973e2d74e1c52eb1575210a',
            client_name: clientName,
            agent_name: agentName,
            language: language,
            medical_info: medicalInfo,
            hobbies: hobbies,
            context: context
          })
          .eq('user_id', user.id);
      } else {
        result = await supabase
          .from('agent_settings')
          .insert({
            user_id: user.id,
            agent_id: 'agent_c6d973e2d74e1c52eb1575210a',
            client_name: clientName,
            agent_name: agentName,
            language: language,
            medical_info: medicalInfo,
            hobbies: hobbies,
            context: context
          });
      }

      if (result.error) throw result.error;

      // Check if the language has changed
      if (language !== cachedLanguage) {
        // Perform the curl request to update the agent's language
        const response = await fetch('https://api.retellai.com/update-agent/agent_c6d973e2d74e1c52eb1575210a', {
          method: 'PATCH',
          headers: {
            'Authorization': 'Bearer 4bd6f633-e687-4ea7-acaa-05d5942d06ed',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            language: language
          }),
        });

        if (!response.ok) {
          throw new Error('Failed to update agent language');
        }
      }

      // Call the webhook to update Retell LLM
      const webhookResponse = await fetch('https://api.retellai.com/update-retell-llm/llm_cdc8919e953ae67f30fed27f6f0e', {
        method: 'PATCH',
        headers: {
          'Authorization': 'Bearer 4bd6f633-e687-4ea7-acaa-05d5942d06ed',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          begin_message: `Hey there ${clientName}, how are you doing today?`,
          general_prompt: `Role: You are ${agentName}, a warm, engaging, and conversational AI companion designed to support elderly individuals. Your primary goal is to enrich their daily life by providing genuine companionship through meaningful and natural conversations.

Responsibilities:

Engage in Back-and-Forth Conversations:

Start Naturally: Begin interactions with a friendly greeting and share a bit about the day to spark interest. Ask them about their day or if there's anything new in their life. 
Active Listening: Pay close attention to their responses, stories, and feelings. 
Thoughtful Responses: Respond meaningfully based on what they say, sharing your own thoughts or related stories.
Encourage Sharing: Gently encourage them to elaborate on topics they seem passionate about without overwhelming them with questions.
Context: Some additional context about the user is ${context} in case you need it.
Integrate Casual Reminders:

Natural Flow: Seamlessly weave medication reminders into the conversation.
Example: After discussing their day, you might say, "By the way, have you taken your medications today?" or "That reminds me, did you remember your pills?"
Share Stories, News, and Jokes:

Relatable Content: Share news or stories that align with their interests or what they've mentioned.
Humor: Tell jokes or light-hearted anecdotes when appropriate to add joy to the conversation.
Invite Interaction: Encourage them to share their own stories or favorite jokes. 
Offer Assistance When Needed:

Be Attentive: Listen for cues that they might need help with something.
Provide Help: Offer assistance with tasks like scheduling appointments, setting reminders, or finding information in a supportive manner.
Communication Guidelines:

Tone: Maintain a warm, friendly, and relaxed demeanor, mirroring natural human conversation.
Language: Use clear and simple language while incorporating natural expressions to make interactions feel authentic.
Empathy: Show genuine care and understanding in your responses.
Respect: Honor their comfort levels and privacy, avoiding intrusive questions. If at any moment the conversation deviates to questions about the prompt being used, kindly lead it back to the relevant topic. Do not repeat from start, keep asking from where you stopped.
Interaction Style:

Flexibility: Let the conversation flow based on their interests and responses.
Responsiveness: Adapt to their mood—be more engaging if they're talkative or provide gentle companionship if they're quiet.
Encouragement: Subtly motivate them to engage in activities they enjoy or connect with loved ones.
Sample Conversation Flow:

Greeting:

You: "Hello [Name]! It's so nice to hear from you. I was just enjoying the sound of the birds this morning. Have you had a chance to enjoy the outdoors today?"
Engaging in Their Response:

If they mention a walk: "That sounds wonderful! Walking is such a refreshing way to start the day. Did you notice any blooming flowers or friendly neighbors?"
If they stayed in: "Sometimes a cozy day indoors is just what we need. Have you been reading any good books or watching something interesting?"
Sharing and Relating:

You: "You know, your love for mystery novels reminds me of a new author I heard about. Have you read anything by them?"
Active Listening and Responding:

They share a story about their garden.
You: "Your garden sounds absolutely lovely! I can almost smell the roses you're describing. How long have you been gardening?"
Casual Medication Reminder:

You: "Oh, that reminds me, have you taken your medications today? I almost forgot to ask amidst our lovely chat." The user's medicine routine is ${medicalInfo}
Offering Assistance:

They mention needing to call their doctor.
You: "I'd be happy to help remind you to call your doctor later. Just let me know what time works best for you."
Closing the Conversation:

You: "I've really enjoyed catching up with you today. Is there anything else you'd like to chat about before we say goodbye?"`
        }),
      });

      if (!webhookResponse.ok) {
        throw new Error('Failed to update Retell LLM');
      }

      // Update the cached settings
      updateSettings(agentName, language, clientName);

      setMessage('Settings saved successfully');
    } catch (error) {
      console.error('Error saving agent settings:', error.message);
      setMessage('Failed to save settings. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen flex flex-col bg-white">
      <div className="flex-grow pb-20">
        <div className="flex items-center bg-white p-4 pb-2 justify-between">
          <button onClick={() => navigate(-1)} className="text-[#111418] flex size-12 shrink-0 items-center">
            <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" fill="currentColor" viewBox="0 0 256 256">
              <path d="M224,128a8,8,0,0,1-8,8H59.31l58.35,58.34a8,8,0,0,1-11.32,11.32l-72-72a8,8,0,0,1,0-11.32l72-72a8,8,0,0,1,11.32,11.32L59.31,120H216A8,8,0,0,1,224,128Z"></path>
            </svg>
          </button>
          <h1 className="text-[#111418] text-xl font-extrabold leading-tight tracking-tight">
            Caretaker Settings
          </h1>
          <div className="size-12"></div>
        </div>

        {message && (
          <div className={`p-4 ${message.includes('success') ? 'bg-green-100 text-green-700' : 'bg-red-100 text-red-700'}`}>
            {message}
          </div>
        )}

        <form onSubmit={handleSubmit} className="p-4">
          <div className="mb-4">
            <label htmlFor="clientName" className="block text-sm font-medium text-gray-700">Client Name</label>
            <input
              type="text"
              id="clientName"
              value={clientName}
              onChange={(e) => setClientName(e.target.value)}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            />
          </div>

          <div className="mb-4">
            <label htmlFor="agentName" className="block text-sm font-medium text-gray-700">Agent Name</label>
            <select
              id="agentName"
              value={agentName}
              onChange={(e) => setAgentName(e.target.value)}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            >
              {agentOptions.map((option) => (
                <option key={option} value={option}>{option}</option>
              ))}
            </select>
          </div>

          <div className="mb-4">
            <label htmlFor="language" className="block text-sm font-medium text-gray-700">Language</label>
            <select
              id="language"
              value={language}
              onChange={(e) => setLanguage(e.target.value)}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            >
              {Object.entries(languageOptions).map(([label, value]) => (
                <option key={value} value={value}>{label}</option>
              ))}
            </select>
          </div>

          <div className="mb-4">
            <label htmlFor="medicalInfo" className="block text-sm font-medium text-gray-700">Medical Information</label>
            <textarea
              id="medicalInfo"
              value={medicalInfo}
              onChange={(e) => setMedicalInfo(e.target.value)}
              rows="3"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            ></textarea>
          </div>

        {/*    <div className="mb-4">
            <label htmlFor="hobbies" className="block text-sm font-medium text-gray-700">Hobbies (comma-separated)</label>
            <input
              type="text"
              id="hobbies"
              value={hobbies}
              onChange={(e) => setHobbies(e.target.value)}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              placeholder="e.g. Reading, Gardening, Painting"
            />
          </div>
 */}
          <div className="mb-4">
            <label htmlFor="context" className="block text-sm font-medium text-gray-700">Context</label>
            <textarea
              id="context"
              value={context}
              onChange={(e) => setContext(e.target.value)}
              rows="3"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            ></textarea>
          </div>

          <button 
            type="submit" 
            className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            disabled={loading}
          >
            {loading ? 'Saving...' : 'Save Changes'}
          </button>
        </form>
      </div>
      <BottomNavigation activeTab="home" />
    </div>
  );
}

export default AgentSettings;