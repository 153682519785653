import React, { useState, useEffect } from 'react';
import BottomNavigation from './BottomNavigation';
import { Link, useNavigate } from 'react-router-dom';
import { supabase } from '../backend/supabaseClient';
import { useAgentSettings } from '../hooks/useAgentSettings';


const languageOptions = {
  'English (US)': 'en-US',
  'English (UK)': 'en-GB',
  'English (India)': 'en-IN',
  'Multilingual': 'multi',
  'Spanish (Spain)': 'es-ES',
  'Spanish (Latin America)': 'es-419',
  'French': 'fr-FR',
  'Mandarin': 'zh-CN',
  'German': 'de-DE',
  'Hindi': 'hi-IN',
  'Japanese': 'ja-JP',
  'Portuguese (Portugal)': 'pt-PT',
  'Portuguese (Brazil)': 'pt-BR',
  'Russian': 'ru-RU',
  'Italian': 'it-IT',
  'Korean': 'ko-KR',
  'Dutch': 'nl-NL',
  'Polish': 'pl-PL',
  'Turkish': 'tr-TR',
  'Vietnamese': 'vi-VN'
};

function Home() {
  const navigate = useNavigate();
  const { agentName, language, clientName, loading } = useAgentSettings();
  const [lastContacted, setLastContacted] = useState(null);

  useEffect(() => {
    fetchLastContacted();
  }, []);

  const fetchLastContacted = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('No user logged in');

      const { data, error } = await supabase
        .from('agent_settings')
        .select('last_contacted')
        .eq('user_id', user.id)
        .single();

      if (error) throw error;

      if (data) {
        setLastContacted(data.last_contacted);
      }
    } catch (error) {
      console.error('Error fetching last contacted:', error.message);
    }
  };

  const formatLastContacted = (timestamp) => {
    if (!timestamp) return 'Never';
    const date = new Date(timestamp);
    const now = new Date();
    const diffTime = Math.abs(now - date);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    
    if (diffDays === 0) return 'Today';
    if (diffDays === 1) return 'Yesterday';
    return `${diffDays} days ago`;
  };

  const getLanguageLabel = (languageCode) => {
    return Object.entries(languageOptions).find(([label, value]) => value === languageCode)?.[0] || languageCode;
  };

  return (
    <div className="min-h-screen flex flex-col bg-white">
      <div className="flex-grow pb-20">
        <div>
          <div className="flex items-center bg-white p-4 pb-2 justify-between">
            <h1 className="text-[#111418] text-xl font-extrabold leading-tight tracking-tight">
              elder<span className="text-[#1980e6]">.ai</span>
            </h1>
          </div>
          
          {/* My Agent card */}
          <div className="mx-4 my-6 rounded-xl overflow-hidden bg-white shadow-md">
            <h3 className="text-[#111418] text-lg  leading-tight tracking-[-0.015em] px-4 py-3 border-b border-[#f0f2f4]">
              Your Caretaker for <span className="font-normal">{clientName}</span>
            </h3>
            <div className="p-4">
              {loading ? (
                <p>Loading agent settings...</p>
              ) : (
                <>
                  <div className="flex items-center mb-4">
                    <img src="https://media.licdn.com/dms/image/v2/C5603AQFqrbMmVxg4NQ/profile-displayphoto-shrink_800_800/profile-displayphoto-shrink_800_800/0/1529621834651?e=1732752000&v=beta&t=I8FhJwWJ1uS6lzqizG2nKhvFEC5cB0_GG1dUfThHrys" alt={agentName} className="w-16 h-16 rounded-full mr-4" />
                    <div className="flex-grow">
                      <div className="flex justify-between items-center">
                        <p className="text-[#111418] text-base font-medium"><span className="font-normal">{agentName}</span></p>
                        <button 
                          className="bg-black text-white px-4 py-2 rounded-full text-sm font-medium ml-4"
                          onClick={() => navigate('/agent-settings')}
                        >
                          Modify Agent
                        </button>
                      </div>
                    </div>
                  </div>
                  <p className="text-[#111418] text-sm font-bold mb-1">+1 (619) 648-5211</p>
                  <p className="text-[#637588] text-sm mb-1">Language: {getLanguageLabel(language)}</p>
                  <p className="text-[#637588] text-sm mb-1 flex items-center">
                    Status: Active
                    <span className="ml-2 w-2 h-2 bg-green-500 rounded-full inline-block"></span>
                  </p>
                  
                </>
              )}
            </div>
          </div>

          {/* understand card */}
          <div className="mx-4 my-6 rounded-xl overflow-hidden relative shadow-md">
            <img 
              src="https://cdn.usegalileo.ai/stability/5171a3e4-a1ca-46dc-8f64-44126fd6169b.png" 
              alt="Elder care" 
              className="w-full h-60 object-cover"
            />
            <div className="absolute inset-0 bg-black bg-opacity-40 flex flex-col justify-end p-4">
              <h2 className="text-white text-lg font-bold mb-4">
                Understand more about your elder's mental health
              </h2>
              <button className="bg-white text-black px-3 py-2 rounded-lg text-sm font-medium w-full">
                Learn More
              </button>
            </div>
            <div className="absolute top-2 right-2">
              <div className="bg-black bg-opacity-40 rounded-full p-1">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                  <line x1="5" y1="12" x2="19" y2="12"></line>
                  <polyline points="12 5 19 12 12 19"></polyline>
                </svg>
              </div>
            </div>
          </div>

          {/* medicine card */}
          <div className="mx-4 my-6 rounded-xl overflow-hidden bg-white shadow-md">
            <h3 className="text-[#111418] text-lg font-bold leading-tight tracking-[-0.015em] px-4 py-3 border-[#f0f2f4]">Medicine Information</h3>
            <div className="p-4">
              <img 
                src="https://cdn.usegalileo.ai/sdxl10/6c3cb2ab-9d3b-473a-8c6d-de9564cf5793.png" 
                alt="Medicine" 
                className="w-full h-48 object-cover rounded-lg mb-4"
              />
              <p className="text-[#637588] text-sm mb-4">Make sure to provide our caretaker with your mom's medicine instructions and reminders.</p>
              <button className="bg-black text-white px-4 py-2 rounded-lg text-sm font-medium w-full">
                Update Info
              </button>
            </div>
          </div>
   
          <div className="mx-4 my-6 rounded-xl overflow-hidden bg-white shadow-md">
            <h3 className="text-[#111418] text-lg font-bold leading-tight tracking-[-0.015em] px-4 py-3  border-[#f0f2f4]">Recent Calls</h3>
            <Link to="/conversations" className="flex items-center gap-4 bg-white px-4 min-h-[72px] py-4 justify-between">
              <div className="flex items-center gap-4">
                <div className="text-[#111418] flex items-center justify-center rounded-lg bg-[#f0f2f4] shrink-0 size-12" data-icon="Phone" data-size="24px" data-weight="regular">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" fill="currentColor" viewBox="0 0 256 256">
                    <path d="M222.37,158.46l-47.11-21.11-.13-.06a16,16,0,0,0-15.17,1.4,8.12,8.12,0,0,0-.75.56L134.87,160c-15.42-7.49-31.34-23.29-38.83-38.51l20.78-24.71c.2-.25.39-.5.57-.77a16,16,0,0,0,1.32-15.06l0-.12L97.54,33.64a16,16,0,0,0-16.62-9.52A56.26,56.26,0,0,0,32,80c0,79.4,64.6,144,144,144a56.26,56.26,0,0,0,55.88-48.92A16,16,0,0,0,222.37,158.46ZM176,208A128.14,128.14,0,0,1,48,80,40.2,40.2,0,0,1,82.87,40a.61.61,0,0,0,0,.12l21,47L83.2,111.86a6.13,6.13,0,0,0-.57.77,16,16,0,0,0-1,15.7c9.06,18.53,27.73,37.06,46.46,46.11a16,16,0,0,0,15.75-1.14,8.44,8.44,0,0,0,.74-.56L168.89,152l47,21.05h0s.08,0,.11,0A40.21,40.21,0,0,1,176,208Z"></path>
                  </svg>
                </div>
                <div className="flex flex-col justify-center">
                  <p className="text-[#111418] text-base font-medium leading-normal line-clamp-1">5 calls</p>
                  <p className="text-[#637588] text-sm font-normal leading-normal line-clamp-2">Last call 2d ago</p>
                </div>
              </div>
              <div className="shrink-0">
                <div className="text-[#111418] flex size-7 items-center justify-center" data-icon="ArrowRight" data-size="24px" data-weight="regular">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" fill="currentColor" viewBox="0 0 256 256">
                    <path d="M221.66,133.66l-72,72a8,8,0,0,1-11.32-11.32L196.69,136H40a8,8,0,0,1,0-16H196.69L138.34,61.66a8,8,0,0,1,11.32-11.32l72,72A8,8,0,0,1,221.66,133.66Z"></path>
                  </svg>
                </div>
              </div>
            </Link>
          </div>
          
          {/* Key Stats Card */}
          <div className="mx-4 my-6 rounded-xl overflow-hidden bg-white shadow-md">
            <h3 className="text-[#111418] text-lg font-bold leading-tight tracking-[-0.015em] px-4 py-3  border-[#f0f2f4]">Key Stats</h3>
            <div className="flex flex-wrap gap-4 p-4">
              <div className="flex min-w-72 flex-1 flex-col gap-2">
                <p className="text-[#111418] text-base font-medium leading-normal">Average Call Duration</p>
                <p className="text-[#111418] tracking-light text-[32px] font-bold leading-tight truncate">30m</p>
                <p className="text-[#637588] text-base font-normal leading-normal">1m</p>
                <div className="flex min-h-[180px] flex-1 flex-col gap-8 py-4">
                  <svg width="100%" height="148" viewBox="-3 0 478 150" fill="none" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none">
                    <path d="M0 109C18.1538 109 18.1538 21 36.3077 21C54.4615 21 54.4615 41 72.6154 41C90.7692 41 90.7692 93 108.923 93C127.077 93 127.077 33 145.231 33C163.385 33 163.385 101 181.538 101C199.692 101 199.692 61 217.846 61C236 61 236 45 254.154 45C272.308 45 272.308 121 290.462 121C308.615 121 308.615 149 326.769 149C344.923 149 344.923 1 363.077 1C381.231 1 381.231 81 399.385 81C417.538 81 417.538 129 435.692 129C453.846 129 453.846 25 472 25V149H326.769H0V109Z" fill="url(#paint0_linear_1131_5935)"></path>
                    <path d="M0 109C18.1538 109 18.1538 21 36.3077 21C54.4615 21 54.4615 41 72.6154 41C90.7692 41 90.7692 93 108.923 93C127.077 93 127.077 33 145.231 33C163.385 33 163.385 101 181.538 101C199.692 101 199.692 61 217.846 61C236 61 236 45 254.154 45C272.308 45 272.308 121 290.462 121C308.615 121 308.615 149 326.769 149C344.923 149 344.923 1 363.077 1C381.231 1 381.231 81 399.385 81C417.538 81 417.538 129 435.692 129C453.846 129 453.846 25 472 25" stroke="#637588" strokeWidth="3" strokeLinecap="round"></path>
                    <defs>
                      <linearGradient id="paint0_linear_1131_5935" x1="236" y1="1" x2="236" y2="149" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#f0f2f4"></stop>
                        <stop offset="1" stopColor="#f0f2f4" stopOpacity="0"></stop>
                      </linearGradient>
                    </defs>
                  </svg>
                </div>
              </div>
            </div>
          </div>
          
          {/* Trends Over Time Card */}
          <div className="mx-4 my-6 rounded-xl overflow-hidden bg-white shadow-md">
            <h3 className="text-[#111418] text-lg font-bold leading-tight tracking-[-0.015em] px-4 py-3  border-[#f0f2f4]">Trends Over Time</h3>
            <div className="flex flex-wrap gap-4 p-4">
              <div className="flex min-w-72 flex-1 flex-col gap-2">
                <p className="text-[#111418] text-base font-medium leading-normal">Call Frequency</p>
                <p className="text-[#111418] tracking-light text-[32px] font-bold leading-tight truncate">Every 2 hours</p>
                <p className="text-[#637588] text-base font-normal leading-normal">3m</p>
                <div className="flex min-h-[180px] flex-1 flex-col gap-8 py-4">
                  <svg width="100%" height="148" viewBox="-3 0 478 150" fill="none" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none">
                    <path d="M0 109C18.1538 109 18.1538 21 36.3077 21C54.4615 21 54.4615 41 72.6154 41C90.7692 41 90.7692 93 108.923 93C127.077 93 127.077 33 145.231 33C163.385 33 163.385 101 181.538 101C199.692 101 199.692 61 217.846 61C236 61 236 45 254.154 45C272.308 45 272.308 121 290.462 121C308.615 121 308.615 149 326.769 149C344.923 149 344.923 1 363.077 1C381.231 1 381.231 81 399.385 81C417.538 81 417.538 129 435.692 129C453.846 129 453.846 25 472 25" stroke="#637588" strokeWidth="3" strokeLinecap="round"></path>
                    <path d="M0 109C18.1538 109 18.1538 21 36.3077 21C54.4615 21 54.4615 41 72.6154 41C90.7692 41 90.7692 93 108.923 93C127.077 93 127.077 33 145.231 33C163.385 33 163.385 101 181.538 101C199.692 101 199.692 61 217.846 61C236 61 236 45 254.154 45C272.308 45 272.308 121 290.462 121C308.615 121 308.615 149 326.769 149C344.923 149 344.923 1 363.077 1C381.231 1 381.231 81 399.385 81C417.538 81 417.538 129 435.692 129C453.846 129 453.846 25 472 25" stroke="#637588" strokeWidth="3" strokeLinecap="round"></path>
                    <defs>
                      <linearGradient id="paint0_linear_1131_5935" x1="236" y1="1" x2="236" y2="149" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#f0f2f4"></stop>
                        <stop offset="1" stopColor="#f0f2f4" stopOpacity="0"></stop>
                      </linearGradient>
                    </defs>
                  </svg>
                </div>
              </div>
            </div>
          </div>
          
          {/* Notifications Card */}
          <div className="mx-4 my-6 rounded-xl overflow-hidden bg-white shadow-md">
            <h3 className="text-[#111418] text-lg font-bold leading-tight tracking-[-0.015em] px-4 py-3 border-b border-[#f0f2f4]">Notifications</h3>
            <div className="flex items-center gap-4 bg-white px-4 min-h-14 justify-between border-b border-[#f0f2f4]">
              <p className="text-[#111418] text-base font-normal leading-normal flex-1 truncate">Missed Call</p>
              <div className="shrink-0"><p className="text-[#637588] text-sm font-normal leading-normal">2d</p></div>
            </div>
            <div className="flex items-center gap-4 bg-white px-4 min-h-14 justify-between border-b border-[#f0f2f4]">
              <p className="text-[#111418] text-base font-normal leading-normal flex-1 truncate">Agent Update</p>
              <div className="shrink-0"><p className="text-[#637588] text-sm font-normal leading-normal">1w</p></div>
            </div>
            <div className="flex items-center gap-4 bg-white px-4 min-h-14 justify-between">
              <p className="text-[#111418] text-base font-normal leading-normal flex-1 truncate">Recommendation</p>
              <div className="shrink-0"><p className="text-[#637588] text-sm font-normal leading-normal">1w</p></div>
            </div>
          </div>
        </div>
      </div>
      <BottomNavigation activeTab="home" />
    </div>
  );
}

export default Home;