import React from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { signOut } from '../backend/auth';
import BottomNavigation from './BottomNavigation';

function SettingItem({ title, value, hasArrow = true, to }) {
  const content = (
    <div className="flex items-center gap-4 bg-white px-4 min-h-14 justify-between">
      <p className="text-[#111418] text-base font-normal leading-normal flex-1 truncate">{title}</p>
      <div className="shrink-0">
        {value && <p className="text-[#111418] text-base font-normal leading-normal">{value}</p>}
        {hasArrow && (
          <div className="text-[#111418] flex size-7 items-center justify-center" data-icon="ArrowRight" data-size="24px" data-weight="regular">
            <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" fill="currentColor" viewBox="0 0 256 256">
              <path d="M221.66,133.66l-72,72a8,8,0,0,1-11.32-11.32L196.69,136H40a8,8,0,0,1,0-16H196.69L138.34,61.66a8,8,0,0,1,11.32-11.32l72,72A8,8,0,0,1,221.66,133.66Z"></path>
            </svg>
          </div>
        )}
      </div>
    </div>
  );

  return to ? <Link to={to}>{content}</Link> : content;
}

function Settings() {
  const navigate = useNavigate();

  const handleSignOut = async () => {
    try {
      await signOut();
      navigate('/login');
    } catch (error) {
      console.error('Failed to sign out:', error);
      // Optionally, show an error message to the user
      alert('Failed to sign out. Please try again.');
    }
  };

  return (
    <div className="min-h-screen flex flex-col bg-white">
      <div className="flex-grow pb-20">
        <div className="flex items-center bg-white p-4 pb-2 justify-between">
          <Link to="/" className="text-[#111418] flex size-12 shrink-0 items-center">
            <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" fill="currentColor" viewBox="0 0 256 256">
              <path d="M224,128a8,8,0,0,1-8,8H59.31l58.35,58.34a8,8,0,0,1-11.32,11.32l-72-72a8,8,0,0,1,0-11.32l72-72a8,8,0,0,1,11.32,11.32L59.31,120H216A8,8,0,0,1,224,128Z"></path>
            </svg>
          </Link>
          <h1 className="text-[#111418] text-xl font-bold leading-tight tracking-tight">
            Settings
          </h1>
          <div className="size-12"></div>
        </div>
        
        <SettingItem title="Caretaker Settings" to="/agent-settings" />
        <SettingItem title="Account" />
        <SettingItem title="Notifications" />
        <SettingItem title="Privacy" />
        <SettingItem title="Help & Support" />
        <SettingItem title="About" />
        
        <div className="mx-4 my-6">
          <button
            onClick={handleSignOut}
            className="w-full bg-red-500 text-white py-2 px-4 rounded-xl font-bold"
          >
            Sign Out
          </button>
        </div>
      </div>
      <BottomNavigation activeTab="settings" />
    </div>
  );
}

export default Settings;